<template>
  <div>
    <app-loading v-show="loadingData"/>
    <v-container
      fill-height
      fluid
      grid-list-xl
    >
      <v-flex
        v-if="!loadingData"
        justify-center
        wrap
      >
        <v-col cols="12">
          <v-card
            color="#385F73"
            dark
          >
            <v-card-subtitle class="text-wrap">
              <p> {{
                  partner.state === 'solicited' ? $vuetify.lang.t('$vuetify.partners.solicit') : $vuetify.lang.t('$vuetify.partners.activate')
                }}</p>
            </v-card-subtitle>
            <v-card-text>
              <v-card-subtitle>
                <p>{{ $vuetify.lang.t('$vuetify.partners.activate_description') }}</p>
                <p v-if="partner.state === 'solicited'">
                  {{ $vuetify.lang.t('$vuetify.partners.solicit_description') }}
                </p>
                <p v-else>
                  {{ $vuetify.lang.t('$vuetify.partners.activate_activation') }}
                </p>
              </v-card-subtitle>
            </v-card-text>
            <v-card-actions>
              <v-btn
                v-if="partner.state === 'approved' && partner.gateway === 'stripe'"
                @click="createAccount('standard')"
              >
                {{ $vuetify.lang.t('$vuetify.partners.existing_account') }}
              </v-btn>
              <a
                v-if="partner.state === 'approved' && partner.gateway === 'paypal'"
                type="button" class="v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--default"
                target="_blank" data-paypal-onboard-complete="onboardedCallback"
                data-paypal-button="true" id="customjs">
                {{ $vuetify.lang.t('$vuetify.partners.existing_account_paypal') }}
              </a>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col
          v-if="!partner.id"
          col="12"
          md="12"
        >
          <partner-info/>
        </v-col>
        <v-col v-if="partner.state === 'solicited'">
          {{ $vuetify.lang.t('$vuetify.partners.wait_result') }}
        </v-col>
        <v-flex
          v-if="partner.state === 'accepted' || partner.state === 'approved'"
          md12
          xs12
          style="margin-top: 15px"
        >
          <material-card class="v-card-profile">
            <v-row
              class="pa-0"
              two-line
            >
              <v-col cols="12" md="4">
                <v-list-item href="#">
                  <v-list-item-action>
                    <v-icon color="indigo">
                      mdi-city
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="getCompanyName"
                    />
                    <v-list-item-subtitle
                      v-text="
                      $vuetify.lang.t(
                        '$vuetify.profile.company'
                      )
                    "
                    />
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="4">
                <v-list-item href="#">
                  <v-list-item-action>
                    <v-icon color="indigo">
                      mdi-account
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title v-text="getFullName"/>
                    <v-list-item-subtitle
                      v-text="
                      $vuetify.lang.t(
                        '$vuetify.firstName'
                      )
                    "
                    />
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" md="4">
                <v-tooltip
                  v-if="userData.isPartner"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item
                      v-clipboard:copy="getReferralLink"
                      v-clipboard:success="onCopy"
                      v-clipboard:error="onError"
                      v-bind="attrs"
                      href="#"
                      v-on="on"
                    >
                      <v-list-item-action>
                        <v-icon color="indigo">
                          mdi-link
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="getReferralLink"
                        />
                        <v-list-item-subtitle
                          v-text="
                          $vuetify.lang.t('$vuetify.referralLink')
                        "
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <span>{{
                      $vuetify.lang.t(
                        '$vuetify.tips.copy_link'
                      )
                    }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </material-card>
        </v-flex>
        <v-flex
          v-if="partner.state === 'accepted' || partner.state === 'approved'"
          md8
          xs12
        >
          <v-row
            cols="12"
          >
            <v-col
              cols="6"
              md="6"
            >
              <linear-statistic
                class="my-4"
                :title="$vuetify.lang.t('$vuetify.partners.referrals')"
                :sub-title="
                  $vuetify.lang.t('$vuetify.partners.referralsSub')
                "
                :quantity="userData.referrals.length > 0 ? userData.referrals.length.toString() : '0'"
                icon="mdi-trending-up"
                color="success"
                :value="100"
              />
            </v-col>
            <v-col
              cols="6"
              md="6"
            >
              <linear-statistic
                class="my-4"
                :title="$vuetify.lang.t('$vuetify.partners.referrer')"
                :sub-title="
                  $vuetify.lang.t('$vuetify.partners.referrerSub')
                "
                :quantity="$vuetify.lang.t('$vuetify.partners.not_specified')"
                icon="mdi-trending-up"
                color="info"
                :value="100"
              />
            </v-col>
          </v-row>
        </v-flex>

        <v-flex
          v-if="partner.state === 'accepted' || partner.state === 'approved'" cols="12" md="12">
          <v-row
            cols="12"
          >
            <v-col cols="12">
              <v-card>
                <v-card-title>
                  {{
                    $vuetify.lang.t(
                      '$vuetify.partners.referralsList'
                    )
                  }}
                  <v-spacer/>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$vuetify.lang.t('$vuetify.actions.search')"
                    single-line
                    hide-details
                  />
                </v-card-title>
                <v-data-table
                  :headers="columnHeaders"
                  :items="userData.referrals"
                >
                  <template v-slot:[`item.plan`]="{ item }">
                    {{  item.plan ?  item.plan : $vuetify.lang.t('$vuetify.no_defined') }}
                  </template>
                  <template v-slot:[`item.period`]="{ item }">
                    {{  item.period ?  $vuetify.lang.t('$vuetify.plan.' + item.period) : $vuetify.lang.t('$vuetify.no_defined') }}
                  </template>
                  <template v-slot:[`item.is_test`]="{ item }">
                    {{
                      item.period ? item.is_test ? $vuetify.lang.t('$vuetify.actions.yes') :
                        $vuetify.lang.t('$vuetify.actions.no') : $vuetify.lang.t('$vuetify.no_defined')
                    }}
                  </template>
                  <template v-slot:[`item.price`]="{ item }">
                    {{
                      item.period ? item.price + ' ' + item.currency : $vuetify.lang.t('$vuetify.no_defined')}}
                  </template>
                  <template v-slot:[`item.percent`]="{ item }">
                    {{ item.period ? item.percent + '%' : $vuetify.lang.t('$vuetify.no_defined') }}
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-flex>
      </v-flex>
    </v-container>
  </div>
</template>
<script>
import PartnerInfo from '../PartnerInfo'
import { mapState, mapActions, mapGetters } from 'vuex'
import localStore from '../../../config/localStorage'
import partner from '../../../api/partner'

export default {
  components: { PartnerInfo },
  data () {
    return {
      loadingData: false,
      affiliateOn: false,
      search: ''
    }
  },
  computed: {
    ...mapState('auth', ['userData', 'pending', 'sessionData', 'partner']),
    getFullName () {
      return `${this.userData.first_name} ${this.userData.last_name || ''}`
    },
    getCompanyName () {
      return `${this.userData.first_name}`
    },
    getReferralLink () {
      return `${process.env.VUE_APP_URL_AFFILIATE + this.userData.affiliate_id}`
    },
    columnHeaders () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.firstName'),
          align: 'left',
          value: 'name'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.company'),
          value: 'company'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.country'),
          value: 'country'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.plan.name'),
          value: 'plan'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.plan.period'),
          value: 'period'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.plan.trial_period'),
          value: 'is_test'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.price'),
          value: 'price'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.plan.partner_percent'),
          value: 'percent'
        }
      ]
    }
  },
  watch: {
    affiliateOn: function () {
    }
  },
  async created () {
    this.loadingData = true
    await this.getUserData().then(() => {
      this.loadingData = false
    })
  },
  async beforeCreate () {
    const localUrl = window.location.href.toString()
    if (localUrl.includes('&')) {
      const partnerId = localUrl.split('&')[0].split('?')[1].split('=')[1]
      const merchantId = localUrl.split('merchantIdInPayPal')[1].split('&')[0].replace('=', '')
      await partner.updatePartnerData({ partner: partnerId, merchantId: merchantId })
        .then(() => {
          window.location.href = window.location.pathname
        })
    }
  },
  methods: {
    ...mapActions('auth', ['getUserData', 'callNewAccount']),
    solicitPartner () {
      this.solicitedPartner(this.newPartner)
    },
    async createAccount (type) {
      await this.callNewAccount({ type: type, origin: window.location.pathname })
        .then(() => {
          if (this.sessionData.url) {
            window.location = this.sessionData.url
          }
        })
    },
    onCopy: function (e) {
      this.$Toast.fire({
        icon: 'success',
        title: this.$vuetify.lang.t(
          '$vuetify.partners.confirm_copied'
        ) + e.text
      })
    },
    onError: function (e) {
      this.$Toast.fire({
        icon: 'error',
        title: this.$vuetify.lang.t(
          '$vuetify.partners.error_copied'
        )
      })
    }
  }
}
</script>
<style scoped>
</style>
